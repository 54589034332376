/* Common styles for both mobile and desktop */
.videos-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 100px;
  }
  
  .videos h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    text-align: center; /* Center align the text */
    margin: 0;
  }
  
  .videos strong {
    color: #15DFED;
  }
  
  .videos p {
    color: #fff;
    font-size: 1rem;
    text-align: center; /* Center align the text */
    margin: 1rem 0;
  }
  
  .videos h3 {
    font-size: 1.5rem;
    text-align: center; /* Center align the text */
    margin-top: 1rem;
  }
  
  /* card */
  .cardcontent6 {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
  
  .video {
    text-align: center;
    margin: 1rem;
    padding: 10px;
    max-width: 400px;
    text-align: center;
  }
  
  .video img {
    width: 100%;
    max-width: 350px;
    height: auto;
  }
  
  .video h5 {
    font-size: 1rem;
    text-align: center;
    margin: 0.5rem 0;
  }
  
  .video p {
    font-size: 0.9rem;
    text-align: center;
    margin: 0.5rem 0;
  }
  
  /* Media query for screens wider than 768px (laptop view) */
  @media (min-width: 769px) {
    .videos h1 {
      font-size: 2.5rem;
    }
  
    .videos p {
      font-size: 1.2rem;
    }
  
    .videos h3 {
      font-size: 1.7rem;
    }
  
    .video img {
      max-width: 350px;
    }
  
    .video h5 {
      font-size: 1rem;
    }
  
    .video p {
      font-size: 0.9rem;
      text-align: center;
    }
  }
  