.LandingPage-container {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Hide horizontal overflow */
  background-color: #000 ;

}
.crowsel{
  width: 100%;
  height: 700px;
}

.video-container {
  max-width: 100%; /* Ensure the video does not exceed its parent container's width */
  height: auto; /* Maintain the video's aspect ratio */
  display: block; /* Ensure the video is a block-level element */
  margin: 0 auto; /* Center the video horizontally within its container */
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Other styles */

.MuiDrawer-root {
  z-index: -11200; /* Increase the z-index value to make sure the drawer appears on top of the carousel */
}



.LandingPage-firstBox div {
  width: 65%;
}

.btn {
  width: 160px;
}

#button-box {
  margin: auto;
}

.LandingPage-secondBox {
  margin-top: 170px;
}

.LandingPage-secondBox-SecondSection {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  width: 90%;
  margin: auto;
  margin-top: 70px;
  border:10px solid red
}

.LandingPage-secondBox-SecondSection div {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
  border-radius: 20px;
  padding-top: 50px;
}

.LandingPage-secondBox-SecondSection div:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  cursor: pointer;
}

.Font {
  position: relative;
  animation-name: example;
  animation-duration: 14s;
  animation-delay: -11s;
}

@keyframes example {
  0% { left: 0px; top: 0px; }
  25% { left: 200px; top: 0px; }
  50% { left: 200px; top: 200px; }
  75% { left: 0px; top: 110px; }
  100% { left: 0px; top: 0px; }
}

a {
  text-decoration: none;
}

.LandingPage-thirdBox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 25px;
  
}

.LandingPage-thirdBox1{
display: flex;
gap: 20px;
flex-wrap: wrap;
  margin-top: 125px;
  
}

.LandingPage-thirdBox img {
  width: 60%;
  height: 450px;
  
}
.LandingPage-thirdBox1 img {
  width: 60%;
  height: 450px;

  
}

.LandingPage-thirdBox div {
  display: flex; 
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  
}
.LandingPage-thirdBox1 div {
  display: flex; 
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  
}

.LandingPage-fourthBox {
   width: 90%;
  margin: auto;
  margin-top: 200px;
}

.LandingPage-fourthBox-secondSection div:hover {
  background-color: rgb(158, 86, 251);
  color: white;
}

.LandingPage-fourthBox-secondSection {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 70px;
}

.LandingPage-fourthBox-secondSection-cards {
  padding: 30px;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 12px;
}

.LandingPage-fourthBox-secondSection-cards:hover {
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  cursor: pointer;
}

.LandingPage-fourthBox-secondSection-cards div {
  display: flex;
  justify-content: center;
}

.LandingPage-fifthBox {
  width: 100%;
  margin: auto;
  margin-top: 250px;
}

.LandingPage-fifthBox div {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-radius: 12px;
  width: 25%;
  margin: auto;
  padding: 40px;
  margin-top: 50px;
  border-radius: 15px;
  margin-bottom: 50px;
  background-color: #f6d4a3;
}

.hero-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  background-color: #000;
  color: #fff;
  padding: 16px;
}

.herotext {
  margin-bottom: 16px;
  font-size: 55px;
  /* text-shadow:15px 15px 80px #b8e647; */
}

.highlight {
  color: #D2FF52;
  font-size: 55px;

}

.hrs {
  width: 6px;
  color: yellow;
  height: 9%; 
  border-top: 8px solid #e3f800;
  background-color: yellow;
  border-radius: 5px;
  box-shadow: 15px 15px 120px 15px yellow;
  position: absolute;
  z-index: 1;
  margin-left:500px;

}
.ed{
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.request-button {
  margin-top: 20px;
  background-color: #D2FF52;
  color: #000;
  padding: 10px;
  border-radius: 30px;
  /* width: 180px; */
  /* display: flex; */
}

.request-button:hover {
  background-color: #c14cd3;
  cursor: pointer;
  transition: 1s;
  color: white;
}

@media all and (min-width: 100px) and (max-width: 500px) {
  .LandingPage-firstBox div {
    width: 80%;
    margin: auto;
    display: grid;
    gap: 30px;
  }
  .crowsel{
    width: 100%;
    height: 400px;
    background-size: cover;
  }
  .video-container {
    max-width: 100%; /* Ensure the video does not exceed its parent container's width */
    height: auto; /* Maintain the video's aspect ratio */
    display: block; /* Ensure the video is a block-level element */
    margin: 0 auto; /* Center the video horizontally within its container */
    padding-top: 75px;
  }
  .LandingPage-fifthBox div {
    width: 75%;
    margin: auto;
  }
  .LandingPage-fourthBox-secondSection {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .LandingPage-thirdBox img {
    width: 96%;
    height: 450px;
  }
  .LandingPage-thirdBox {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    
  }
  .btn {
    width: 120px;
    padding: 10px;
  }
  #button-box {
    width: 90%;
    display: flex;
    justify-content: space-between;
  }
  .LandingPage-secondBox-SecondSection {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }


  .LandingPage-thirdBox1{
    display: grid;
    grid-template-columns: repeat(1,1fr);

  }

  .herotext {
    font-size:large;
    
  }
  .highlight {
    color: #D2FF52;
    font-size: large;
  
  }
  .request-button {
    font-size: 0.875rem;
    padding: 8px 16px;
    color: #070806;
    
  }

  .hrs {
    width: 3px;
    color: yellow;
    height: 3%; 
    border-top: 8px solid #e3f800;
    background-color: yellow;
    border-radius: 5px;
    box-shadow: 15px 15px 120px 15px yellow;
    position: absolute;
    z-index: 1;
    margin-left: 170px;
  
  }


  .hero-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    background-color: #000;
    color: #fff;
    padding: 16px;
    margin-top: 50px;
  }

  .ed{
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .request-button {
    margin-top: 20px;
    background-color: #c14cd3;
    color: #000;
    padding: 10px;
    border-radius: 30px;
    /* width: 180px; */
    /* display: flex; */
  }



}




@media all and (min-width: 501px) and (max-width: 900px) {
  .herotext {
    font-size:xx-large;
  }
  .highlight {
    color: #D2FF52;
    font-size: xx-large;
  
  }
  .request-button {
    font-size: 0.875rem;
    padding: 8px 16px;
    color: #070806;
    
  }
  .hrs {
    width: 4px;
    color: yellow;
    height:5%; 
    border-top: 8px solid #e3f800;
    background-color: yellow;
    border-radius: 5px;
    box-shadow: 15px 15px 120px 15px yellow;
    position: absolute;
    z-index: 1;
    margin-left:200px;
  
  }

  .hero-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100vh;
    background-color: #000;
    color: #fff;
    padding: 16px;
    margin-top: 60px;
  }
  
  .LandingPage-thirdBox1{
    display: grid;
    grid-template-columns: repeat(1,1fr);
    border: 10px solid red;

  }
  .video-container {
    max-width: 100%; /* Ensure the video does not exceed its parent container's width */
    height: auto; /* Maintain the video's aspect ratio */
    display: block; /* Ensure the video is a block-level element */
    margin: 0 auto; /* Center the video horizontally within its container */
    padding-top: 75px;
    background-size: cover;
  }
  .LandingPage-firstBox div {
    width: 80%;
    margin: auto;
    display: grid;
    gap: 30px;
  }
  .LandingPage-fifthBox div {
    width: 80%;
    margin: auto;
  }
  .LandingPage-fourthBox-secondSection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .LandingPage-thirdBox {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    
  }
  .LandingPage-secondBox-SecondSection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .btn {
    width: 120px;
    padding: 10px;
  }
  #button-box {
    width: 60%;
    display: flex;
    justify-content: space-between;
  }
}

@media all and (min-width: 901px) and (max-width: 1100px) {
  .LandingPage-firstBox div {
    width: 80%;
    margin: auto;
    display: grid;
    gap: 30px;
  }
  .LandingPage-fifthBox div {
    width: 80%;
    margin: auto;
  }
  .LandingPage-fourthBox-secondSection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .btn {
    width: 120px;
    padding: 10px;
  }
  #button-box {
    width: 40%;
    display: flex;
    justify-content: space-between;
  }
}