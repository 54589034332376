@import url("https://fonts.googleapis.com/css?family=Bevan");
.ccn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* This ensures that the content fills the entire viewport height */
    background: rgb(40, 40, 40);
    overflow: hidden;
}

.ps {
    font-family: "Bevan", cursive;
    font-size: 130px;
    text-align: center;
    letter-spacing: 5px;
    background-color: black;
    color: transparent;
    text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.1);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.ps span {
    font-size: 1.2em;
}

code {
    color: #bdbdbd;
    text-align: center;
    display: block;
    font-size: 16px;
    margin: 0 30px 25px;
}

code span {
    color: #f0c674;
}

code i {
    color: #b5bd68;
}

code em {
    color: #b294bb;
    font-style: unset;
}

code b {
    color: #81a2be;
    font-weight: 500;
}

.as {
    color: #8abeb7;
    font-family: monospace;
    font-size: 20px;
    text-decoration: underline;
    margin-top: 10px;
    display: inline-block;
}

@media screen and (max-width: 880px) {
    .ps {
        font-size: 14vw;
    }
}
