/* Common styles for both mobile and desktop */
.customer {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 5rem;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
  }
  
  .case h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    margin: 0; /* Remove margin to fix spacing */
  }
  
  .case strong {
    color: #15DFED; /* Highlight strong text with a different color */
  }
  
  .case p {
    color: #fff;
    font-size: 1rem;
    margin: 0; /* Remove margin to fix spacing */
    text-align: center; /* Center align the text */
  }
  
  /* card */
  .cardcontent2 {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 2rem;
  }
  
  .study {
    width: 100%; /* Full width on mobile */
    max-width: 400px; /* Limit width on larger screens */
    margin-top: 20px;
    border-color: #fff;
    font-weight: bold;
    margin: 20px;
    padding: 20px;
    text-align: center;
  }
  
  .study img {
    width: 100%;
    max-width: 350px;
    height: auto;
  }
  
  .study p {
    margin: 0; /* Remove margin to fix spacing */
    color: #fff;
    font-size: 0.9rem;
    line-height: 1.4;
    text-align: center;
  }
  
  /* Media query for screens wider than 768px (laptop view) */
  @media (min-width: 769px) {
    .customer {
      margin-top: 10rem;
    }
  
    .case h1 {
      font-size: 2.5rem;
    }
  
    .case p {
      font-size: 1.2rem;
    }
  
    .study img {
      max-width: 350px;
    }
  
    .study p {
      font-size: 1rem;
      text-align: center;
    }
  }
  