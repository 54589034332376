.rew{
  padding: 180px;
}

.reviewContainer{
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px;
}
.card1 {
  position: relative; /* Necessary for absolute positioning */
  display: flex;
  flex-direction: column;
  height: 400px;
  background-image: url("../../assets//was.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  width: 270px;
}

.card1::before {
  content: "";
  position: absolute;
  bottom: 0; /* Start the gradient at the bottom */
  left: 0;
  width: 100%;
  height: 50%; /* Adjust the height as needed */
  background: linear-gradient(to top, rgba(10, 10, 10, 1.35), rgba(0, 0, 0, 0)); /* Gradient from black to transparent */
  color: white;
}

.card2 {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 150px;
  background-image: url("../../assets/whybringmark/agirl.jpg");
  background-size: cover;
  background-position: center;
  border-radius:10px;
  width: 270px;
  /* margin: 20px; */

}


.card2::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%; 
  background: linear-gradient(to top, rgba(10, 10, 10, 1.35), rgba(0, 0, 0, 0));
  color: white;
}

.card24 {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 150px;
  background-image: url("../../assets/whybringmark/boy.jpg");
  background-size: cover;
  background-position: center;
  border-radius:10px;
  width: 270px;
  /* margin: 20px; */

}


.card24::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%; 
  background: linear-gradient(to top, rgba(10, 10, 10, 1.35), rgba(0, 0, 0, 0));
  color: white;
}

.card3 {
  position: relative; 
  display: flex;
  flex-direction: column;
  height: 400px;
  background-image: url("../../assets/ceo.jpg");
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  width: 270px;
}

.card3::before {
  content: "";
  position: absolute;
  bottom: 0; 
  left: 0;
  width: 100%;
  height: 50%; 
  background: linear-gradient(to top, rgba(10, 10, 10, 1.35), rgba(0, 0, 0, 0)); 
  color: white;
}






.content1 {
  padding: 20px;
margin-top: 200px;


}

.content23 {
  padding: 20px;
  margin-top: -8px;

}


.content3 {
  padding: 20px;
  /* border-radius: 0 0 10px 10px;  */
  margin-top: 200px;
}

.subtitle{
  color: black;
  text-align: center;
  font-size: 10px;
}

.title{
  font-size: 15px;
  color: black;
}


@media screen and (max-width: 768px) {
  .card1, .card2, .card3,.card24 {
    max-width: 100%;
    height: 600px;
    /* margin: 30px; */
    margin-top: 30px;
  }
  .subtitle{
    color: white;
    text-align: center;
    font-size: 10px;
  }
  
.title{
  font-size: 15px;
  color: white;
}



  .content1, .content3 {
    color: antiquewhite;
  }
  .rew{
    padding: 10px;
  }

  

  
.reviewContainer{
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-content: center;
  align-items: center;
  align-self: center;
}

.card1, .card2,.card24 {
display: none;
}




}

@media screen and (max-width: 900px) {
  .card1, .card2, .card3,.card24 {
    width: 100%;
    height: 600px;
    /* margin: 30px; */
  }

  

  .subtitle{
    color: white;
    text-align: center;
    font-size: 10px;
  }
  
.title{
  font-size: 15px;
  color: white;
}


  .content1, .content3, .content2 {
    margin-top: 400px;
    color: aliceblue;
  }
  .rew{
    padding: 10px;
  }

  
.reviewContainer{
  flex-direction: column;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-content: center;
  align-items: center;
  align-self: center;
}
}










