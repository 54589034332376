.page{
    /* background-color: #0D1E31;   */


  overflow-x: hidden;

}


/* image */
.path{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: center;
    align-self: flex-start;
    margin-top: 23vh;
}
.para_container{

    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    margin-top: 60px;
    /* border: 2px solid wheat; */
    width: 50%;
    margin-bottom: 50px;
}

.laptop_container{
    flex-direction: row;
    display: flex;
    align-items: center;
    /* border: 2px solid wheat; */
margin: 60px;
    justify-content: space-between;
    /* margin-top: 30px; */
    
}
.laptop_container1{
    flex-direction: column;
    justify-content: center;
    align-content: center;
    display: flex;
    /* width: 70%; */
    cursor:pointer;
    /* border: 2px solid wheat; */
    /* padding: 10px 10px;     */
    padding: 20px;

    margin-right: 30px;
}
.laptop_container1:hover{
    background-color: #0D1E31;
    cursor:pointer;
    border-radius: 10px;
}
.forcast_cardContainer{
    flex-grow: 1;
    overflow: hidden;
    prefix: 3;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    margin-top: 90px;
    justify-content: space-around;
}
.forcast_cardContainer1{
    flex-grow: 1;
    overflow: hidden;
    prefix: 3;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    margin-top: 90px;
    justify-content: space-around;
}

.forcast_cardContainer2{
    flex-grow: 1;
    overflow: hidden;
    prefix: 3;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    margin-top: 150px;
    justify-content: space-between;
}
.forcast_cardContainer3{
    flex-grow: 1;
    overflow: hidden;
    prefix: 3;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    margin-top: 150px;
    justify-content: space-around;
}
.forcast_cardContainer4{
    flex-grow: 1;
    overflow: hidden;
    prefix: 3;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    margin-top: 150px;
    justify-content: space-around;
}
.forcast_cardContainer5{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  
}

.imgtt{
    display: flex;
    justify-content: center;
}

.img-container{
margin-top: 30px;
/* display: flex; */
flex-direction: row;
justify-content: center;
/* margin: 70px; */
margin-bottom: 30px;

}
@media only screen and (min-width: 600px) { 

    .para_container{
        flex-wrap: wrap;
        display: flex;
        /* width: 100%; */
        align-content: center;
        justify-content: center;
        flex-direction: column;

    }
    .forcast_cardContainer5{
        /* display: flex; */
        /* flex-direction: column; */
        justify-content: center;
      
    }
  
    
    .img-container{
        /* style={{marginTop:"30px",display:"flex",flexDirection:"row",}} */
        margin-top: 30px;
        display: flex;
        flex-direction: column;
   
        /* border: 2px solid white; */
        
        }

    .imgtt{
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        align-self: center;
    }
    .desc{
        padding-left: 15px;
    }


  }