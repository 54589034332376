/* Common styles for both mobile and desktop */
.game {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 5rem;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
  }
  
  .insights {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .insights h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
  }
  
  .insights p {
    color: #fff;
    font-size: 1rem;
  }
  
  .insights button {
    padding: 8px 20px;
    margin-top: 1rem;
    background-color: rgba(54, 177, 169, 0.742);
    color: #fff;
    font-weight: bold;
  }
  
  /* card */
  .industrycard {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 2rem;
  }
  
  .industry h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #ffffff;
  }
  
  .industry p {
    margin-top: 1rem;
    color: #fff;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .industry {
    width: 100%; /* Full width on mobile */
    max-width: 400px; /* Limit width on larger screens */
    margin-top: 20px;
    border-color: #fff;
    font-weight: bold;
    margin: 20px;
    padding: 20px;
  }
  
  /* Media query for screens wider than 768px (laptop view) */
  @media (min-width: 769px) {
    .game {
      margin-top: 10rem;
    }
  
    .insights h1 {
      font-size: 2.5rem;
    }
  
    .insights p {
      font-size: 1.2rem;
    }
  
    .insights button {
      padding: 10px 30px;
      margin-top: 2rem;
    }
  
    .industry h3 {
      font-size: 1.8rem;
    }
  
    .industry p {
      font-size: 1.1rem;
      margin-top: 1rem;
      line-height: 1.6;
    }
  }
  