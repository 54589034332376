
.Services-container{
    width: 100%;

}

.Services-secondBox{

    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 60px;
    width: 90%;
    margin: auto;
    margin-top: 200px;
}
.Services-secondBox div{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    padding: 30px;
    border-radius: 12px;
}

.Services-secondBox div:hover{
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    cursor: pointer;
}

.Services-thirdBox{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 40px;
   
    width: 90%;
    margin: auto;
    margin-top: 200px;
   
}


.Services-thirdBox img{
    height: 90vh;
}


.Services-fourthBox{
    width: 90%;
    margin: auto;
    margin-top: 200px;
}


.Services-fourthBox-secondSection{
     display: grid;
     grid-template-columns: repeat(2,1fr);
     gap: 40px;
     margin-top: 50px;

}


.Services-fourthBox-secondSection div{
    padding: 40px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    border-radius: 12px;
}


.Services-fourthBox-secondSection div:hover{
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}



@media all and (min-width: 501px) and (max-width: 900px) {

    .Services-secondBox{

        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 10px;

    }

    .Services-fourthBox-secondSection{
     
        grid-template-columns: repeat(1,1fr);
        gap: 20px;
    }

    
    .Services-thirdBox{
        display: grid;
        grid-template-columns: repeat(1,1fr);

    }

    

}

@media all and (min-width: 901px) and (max-width: 1100px) {
    .Services-secondBox{

        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 10px;
        

    }

    .Services-fourthBox-secondSection{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 20px;
    }

    .Services-thirdBox{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        
    }
      

}




@media all and (min-width: 100px) and (max-width: 500px) {
    .Services-secondBox{

        display: grid;
        grid-template-columns: repeat(1,1fr);
        margin-top: 50px;
        

    }

    .Services-fourthBox-secondSection{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 20px;
    }
 

    .Services-thirdBox{
        display: grid;
        grid-template-columns: repeat(1,1fr);
        
    }

    .Services-thirdBox img{
        height: 40vh;
        width: 100%;
    }

 
}