.table-row{
display: flex;
flex-direction: row;
justify-content: space-around;
/* flex-wrap: wrap; */

}

/* justifyContent="space-between" alignItems="center" */
.imgl{
  display: flex;
  justify-content: center;
  align-items: center;
}


.foot{
  background-color:#1A1C1F ;
  color: aliceblue;
  /* margin-top: 286px; */
  padding: 24px;
}


@media all and (min-width: 100px) and (max-width: 768px) {
  .table-row{
  display: flex;
  flex-direction: column;
  justify-content: center;

  }
  .imgl{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: center;
  }

  .foot{
    background-color:rgb(9,21,35) ;
    color: aliceblue;
    margin-top: 130px;
    padding: 24px;
  }

}
/* 
@media only screen and (min-width: 768px) {

  .foot{
    background-color:rgb(9,21,35) ;
    color: aliceblue;
    margin-top: 286px;
    padding: 24px;
  }

} */


@media all and (max-width: 100px) and (min-width: 768) {
  .table-row{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  
  }
  .foot{
    background-color:rgb(9,21,35) ;
    color: aliceblue;
    margin-top: 286px;
    padding: 24px;
  }
}

