/* In your CSS file (e.g., MyComponent.css) */
.main{
  /* background-color: rgb(9,21,35) ; */
  /* background-color: black; */
  margin-top: 180px;
}
.logo-image {
  display: block; /* Show the logo by default */

  @media (max-width: 768px) {
    display: none; /* Hide the logo on screens narrower than 768px */
  }
}
