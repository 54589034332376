.noContainer{
  max-width: 180px;
  height: 180px;
  background-color: #1D2023;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  border: 1px solid  #666666;
  border-radius: 10px;
  border-image-source: linear-gradient(307.4deg, #000000 0%, #666666 101.89%);
  margin-top: 30px;
}

@media all and (min-width: 100px) and (max-width: 500px) {

  .noContainer{
    max-width: 150px;
    height: 100px;
    background-color: #1D2023;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border: 1px solid  #666666;
    border-radius: 10px;
    border-image-source: linear-gradient(307.4deg, #000000 0%, #666666 101.89%);
  }


}

@media all and (min-width: 100px) and (max-width: 600px) {

  .noContainer{
    max-width: 160px;
    height: 100px;
    background-color: #1D2023;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border: 1px solid  #666666;
    border-radius: 10px;
    border-image-source: linear-gradient(307.4deg, #000000 0%, #666666 101.89%);
  }


}

@media all and (min-width: 100px) and (max-width: 768px) {

  .noContainer{
    max-width: 170px;
    height: 100px;
    background-color: #1D2023;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border: 1px solid  #666666;
    border-radius: 10px;
    border-image-source: linear-gradient(307.4deg, #000000 0%, #666666 101.89%);
  }


}