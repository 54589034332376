web_container{
  overflow-x: hidden;
  max-width: 100%;
}
.LandingPage-container {
    width: 100%;
    text-align: center;
    
  }
  
  /* style={{    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"}} */
  * {
    margin: 0;
    padding: 0;
    /* box-sizing: border-box;
    color: #fff;
    font-family: 'Ubuntu', sans-serif; */
  }
  /* LandingPage.css */
  
  .LandingPage-container {
    position: relative;
  }
  
  /* Other styles */
  
  .MuiDrawer-root {
    z-index: -11200; /* Increase the z-index value to make sure the drawer appears on top of the carousel */
  }
  
  .LandingPage-firstBox {
    width: 100%;
    padding-top: 150px;
    padding-bottom: 100px;
    background-image: url("https://wallpaperaccess.com/full/516010.jpg");
    background-size: cover;
    width: 100%;
    height: 70vh;
  }
  
  .LandingPage-firstBox div {
    width: 65%;
  }
  
  .btn {
    width: 160px;
  }
  
  #button-box {
    margin: auto;
  }
  
  /* .LandingPage-secondBox {
    margin-top: 200px;
  } */
  
  .LandingPage-secondBox-SecondSection {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 50px;
    width: 90%;
    margin: auto;
    margin-top: 70px;
  }
  
  .LandingPage-secondBox-SecondSection div {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    border-radius: 20px;
    padding-top: 50px;
  }
  
  .LandingPage-secondBox-SecondSection div:hover {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    cursor: pointer;
  }
  
  .Font {
    position: relative;
    animation-name: example;
    animation-duration: 14s;
    animation-delay: -11s;
  }
  
  @keyframes example {
    0% { left: 0px; top: 0px; }
    25% { left: 200px; top: 0px; }
    50% { left: 200px; top: 200px; }
    75% { left: 0px; top: 110px; }
    100% { left: 0px; top: 0px; }
  }
  
  a {
    text-decoration: none;
  }
  
  .LandingPage-thirdBox {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 25px;
    
  }
  
  .LandingPage-thirdBox img {
    width: 60%;
    height: 450px;
    
  }
  
  .LandingPage-thirdBox div {
    display: flex; 
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    
  }
  
  .LandingPage-fourthBox {
     width: 90%;
    margin: auto;
    margin-top: 200px;
  }
  
  .LandingPage-fourthBox-secondSection div:hover {
    background-color: rgb(158, 86, 251);
    color: white;
  }
  
  .LandingPage-fourthBox-secondSection {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-top: 70px;
  }
  
  .LandingPage-fourthBox-secondSection-cards {
    padding: 30px;
    padding-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    border-radius: 12px;
  }
  
  .LandingPage-fourthBox-secondSection-cards:hover {
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    cursor: pointer;
  }
  
  .LandingPage-fourthBox-secondSection-cards div {
    display: flex;
    justify-content: center;
  }
  
  .LandingPage-fifthBox {
    width: 100%;
    margin: auto;
    margin-top: 250px;
  }
  
  .LandingPage-fifthBox div {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    border-radius: 12px;
    width: 25%;
    margin: auto;
    padding: 40px;
    margin-top: 50px;
    border-radius: 15px;
    margin-bottom: 50px;
    background-color: #f6d4a3;
  }
  
  @media all and (min-width: 100px) and (max-width: 500px) {
    .LandingPage-firstBox div {
      width: 80%;
      margin: auto;
      display: grid;
      gap: 30px;
    }
    .LandingPage-fifthBox div {
      width: 75%;
      margin: auto;
    }
    .LandingPage-fourthBox-secondSection {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
    .LandingPage-thirdBox img {
      width: 96%;
      height: 450px;
    }
    .LandingPage-thirdBox {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      
    }
    .btn {
      width: 120px;
      padding: 10px;
    }
    #button-box {
      width: 90%;
      display: flex;
      justify-content: space-between;
    }
    .LandingPage-secondBox-SecondSection {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  @media all and (min-width: 501px) and (max-width: 900px) {
    .LandingPage-firstBox div {
      width: 80%;
      margin: auto;
      display: grid;
      gap: 30px;
    }
    .LandingPage-fifthBox div {
      width: 80%;
      margin: auto;
    }
    .LandingPage-fourthBox-secondSection {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    .LandingPage-thirdBox {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      
    }
    .LandingPage-secondBox-SecondSection {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    .btn {
      width: 120px;
      padding: 10px;
    }
    #button-box {
      width: 60%;
      display: flex;
      justify-content: space-between;
    }
  }
  
  @media all and (min-width: 901px) and (max-width: 1100px) {
    .LandingPage-firstBox div {
      width: 80%;
      margin: auto;
      display: grid;
      gap: 30px;
    }
    .LandingPage-fifthBox div {
      width: 80%;
      margin: auto;
    }
    .LandingPage-fourthBox-secondSection {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    .btn {
      width: 120px;
      padding: 10px;
    }
    #button-box {
      width: 40%;
      display: flex;
      justify-content: space-between;
    }
  }