
.slide-track img {
    width: 250px;
    height: 150px;
    animation: scroll 50s linear infinite;
    border-radius: 10px;

  }
  
  .slide-track {
    display: flex;
    gap: 4em;
    overflow-x: auto; /* Enable horizontal scrolling for overflow */
    padding-bottom: 20px; /* Add some bottom padding to account for scrollbar */
  }
  
  .slide {
    flex-shrink: 0; /* Prevent the slides from shrinking to fit */
    width: 250px;
    height: 150px;
    animation: scroll 50s linear infinite;
    border-radius: 10px;
  }
  
  .slider {
    padding: 5em 2em;
    background-color: #0000;
    border-radius: 10px;
    overflow: hidden; /* Hide horizontal overflow */
    /* margin-top: 100px; */

  }
  
  
  @keyframes scroll {
    0% {transform: translateX(0);}
    100% {transform: translatex(-1900%)}
  }

  