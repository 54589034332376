/* Common styles for both mobile and desktop */
.report-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 100px;
  }
  
  .Report {
    text-align: center;
    margin: 2rem 0;
  }
  
  .Report h1 {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
  }
  
  .Report h3 {
    font-size: 1.5rem;
    text-align: center;
    margin: 1rem 0;
  }
  
  .Report p {
    font-size: 1rem;
    text-align: center;
    margin: 1rem 0;
  }
  
  .Report button {
    background-color: black;
    border-color: #fff;
    padding: 10px 20px;
    font-weight: bold;
    font-size: 1rem;
  }
  
  .cardcontent5 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .report {
    text-align: center;
    margin: 2rem;
    padding: 20px;
    flex-basis: calc(33.33% - 2rem); /* Display one card per row in mobile */
  }
  
  .report img {
    width: 100%;
    height: auto;
  }
  
  .description2 {
    text-align: center;
    margin: 1rem 0;
  }
  
  .description2 h5 {
    font-size: 1rem;
    text-align: center;
  }
  
  .description2 p {
    font-size: 1rem;
    text-align: center;
    margin: 0.5rem 0;
  }
  
  /* Media query for mobile view */
  @media (max-width: 768px) {
    .Report h1 {
      font-size: 1.5rem;
    }
  
    .Report h3 {
      font-size: 1.2rem;
    }
  
    .Report p {
      font-size: 0.9rem;
    }
  
    .Report button {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  
    .report {
      margin: 1rem;
      padding: 10px;
      text-align: center;
      flex-basis: calc(100% - 2rem); /* Display one card per row in mobile */
    }
  
    .report img {
      max-width: 100%;
      height: auto;
    }
  
    .description2 h5 {
      font-size: 0.9rem;
    }
  
    .description2 p {
      font-size: 0.9rem;
      margin: 0.5rem 0;
    }
  }
  