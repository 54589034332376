.news {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 100px;
  }
  
  .letter {
    text-align: center;
    margin: 2rem 0;
  }
  
  .letter h1 {
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
  }
  
  .letter p {
    font-size: 1rem;
    text-align: center;
    margin: 1rem 0;
  }
  
  .cardcontent3 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .News {
    text-align: center;
    margin: 2rem;
    padding: 20px;
  }
  
  .News img {
    width: 100%;
    max-width: 350px;
    height: auto;
  }
  
  .News p {
    font-size: 1rem;
    margin: 1rem 0;
  }
  
  .News button {
    background-color: black;
    border-color: #fff;
    padding: 10px 20px;
    font-weight: bold;
    font-size: 1rem;
  }
  