nav {
    background: #0E0F1166;
    position: fixed;
    width: 100%;
    z-index: 999;
    height: auto;
    line-height: 65px;
    top: 0;
    /* opacity: ; */
}

.ww{
    background-color:#091523;
    background-color: chartreuse;
}
nav .wrapper {
    position: relative;
    /* max-width: 1250px; */
    padding: 0 30px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content:space-evenly;
}

.wrapper .logo .ww {
    color: #fff;
    text-decoration: none;
    font-size: 27px;
    font-weight: 600;
    /* cursor: pointer; */

}
.logo{
    /* justify-content: center; */
    display: flex;
    cursor: pointer;
}

.wrapper .nav-links {
    display: inline-flex;
    
}
.conts{
    list-style-type: none;
}
.nav-links li {
    list-style: none;
    margin: 0 10px;
    
}

.nav-links li .ww {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    padding: 9px 15px;
    border-radius: 5px;
    transition: all 0.3s ease;
    

}


.drop-down-icon {
    /* color:yellow !important; */
    font-size: 25px;
    position: relative;
    top: 6px;
}

.nav-links li .ww :hover {
    /* background: #0e233a; */
    transform: scale(1);
    /* background-color: black; */

}

.nav-links li .ww  {
    display: inline;
    position: relative;
    overflow: hidden;
    /* background-color: black; */

}

.nav-links li .ww :after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: -5px;
    /* background:yellow; */
    height: 4px;
    transition-property: left right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    /* background-color: black; */

    /* border: 2px solid wheat; */
    /* color: yellow; */
}

.nav-links li .ww :hover:after,
.nav-links li .ww :focus:after,
.nav-links li .ww :active:after {
    right: 0;
    /* color: yellow; */
    background-color: #091523;

}

.nav-links .drop-menu {
    /* background: #091523; */
    background-color: #091523;

    width: 240px;
    top: 85px;
    line-height: 45px;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    /* margin-top: 20px; */
}

.nav-links li:hover .drop-menu,
.nav-links li:hover .mega-box {
    top: 65px;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;
    /* color: yellow; */
}

.drop-menu li .ww  {
    width: 100%;
    display: flex;
    /* padding: 0 0 0 15px; */
    font-weight: 400;
    border-radius: 0px;
    /* border: 2px solid white; */

}

.mega-box {
    position: absolute;
    top: 85px;
    width: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    /* border: 2px solid wheat; */
}

.mega-box .content {
    /* background: #091523; */
    background-color: #091523;

    left: 0;
    padding: 25px 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    /* margin-top: 11px;     */

}

.content .row {
    width: calc(25% - 30px);
    line-height: 45px;
    /* border-left: 1px solid rgba(255, 255, 255, 0.09); */
    margin-top: 30px;
}

.content .row header {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    width: 100%;
    text-align: start;
    display: flex;
    justify-content: flex-start;
    padding: 5px;
}

.content .row ul {
    border-left: 1px solid rgba(255, 255, 255, 0.09);
}


.row .mega-links li .ww  {
    padding: 0 -50px;
    color: #cfd7e0;
    font-size: 17px;
    display: block;
}

.drop-menu li:hover .drop-menu-1 {
    left: 180px;
    opacity: 1;
    visibility: visible;
    transition: all 0.5s ease;
    /* color: yellow; */
}

.drop-menu-1 {
    display: block;
    position: absolute;
    top: 0;
    left: 192px;
    width: 100%;
    margin-top: 5px;
    background: #091523;
    visibility: hidden;
}

.show,
.icon,
input {
    display: none;
}

@media screen and (max-width:1000px) {
    .wrapper {
        flex-direction: column;
        align-items: center;
        padding: 0 15px;
    }
    .conts{
       display: none;
    }

    .wrapper .logo {
        order: -1;
        /* padding-top: 20px;  */
        text-align: left;
        width: 100%;
    }

    .wrapper .nav-links {
        text-align: left;
        display: none;
        top: 0;
        background-color: red;
        width: 100%;
    }

    .nav-links li {
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;
    }


    .nav-links a {
        display: block;
        padding: 10px 15px;
    }

    .nav-links li a {
        display: block;
        padding: 10px 15px;
    }

    .drop-menu {
        top: 65px;
    }

    .mega-box {
        top: 85px;
    }

    nav ul ul {
        top: 70px;
        position: static;
    }

    .icon {
        display: block;
        color: white;
        position: absolute;
        right: 40px;
        line-height: 70px;
        font-size: 25px;
        cursor: pointer;
    }

}

