/* Common styles for both mobile and desktop */
.blog {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    margin-top: 5rem;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
  }
  .description1{
    margin: 10px;

  }
  
  .impact h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    margin: 0;
  }
  
  .impact p {
    color: #fff;
    font-size: 1rem;
    margin: 0; /* Remove top margin to fix spacing */
    text-align: center; /* Center align the text */
  }
  
  /* card */
  .cardcontent1 {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-top: 2rem;
  }
  
  .retail {
    width: 100%; /* Full width on mobile */
    max-width: 400px; /* Limit width on larger screens */
    margin-top: 20px;
    border-color: #fff;
    font-weight: bold;
    margin: 20px;
    padding: 20px;
    text-align: center;
  }
  
  .retail img {
    width: 100%;
    max-width: 350px;
    height: auto;
  }
  
  .description1 h5 {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .description1 p {
    color: #fff;
    font-size: 0.9rem;
    line-height: 1.4;
    /* text-align: center; */
  }
  
  /* Media query for screens wider than 768px (laptop view) */
  @media (min-width: 769px) {
    .blog {
      margin-top: 10rem;
    }
  
    .impact h1 {
      font-size: 2.5rem;
    }
  
    .impact p {
      font-size: 1.2rem;
    }
  
    .retail img {
      max-width: 350px;
    }
  
    .description1 h5 {
      font-size: 1.3rem;
    }
  
    .description1 p {
      font-size: 1rem;
      text-align: center;
    }
  }
  