.award-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .award h1 {
    font-size: 2.5rem;
    margin: 2rem;
    color: #15dfed;
    font-weight: bolder;
    margin-top: 5rem;
    text-align: center;
  }
  
  .award h3 {
    margin: 2rem;
    font-size: 2rem;
    text-align: center;
  }
  
  .award p {
    font-size: 1.2rem;
    margin: 2rem;
    text-align: center;
  }
  
  .recognize h3 {
    font-size: 2rem;
    margin: 2rem;
    text-align: center;
  }
  
  .recognize p {
    margin: 2rem;
    font-size: 1.2rem;
    text-align: center;
  }
  
  .winner h3 {
    font-size: 2rem;
    text-align: center;
  }
  
  .winner p {
    margin: 2rem;
    font-size: 1.2rem;
    text-align: center;
  }
  
  .carterdata h1 {
    font-size: 2.8rem;
    color: aqua;
    margin: 2rem;
    margin-top: 5rem;
    text-align: center;
  }
  
  .carterdata {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
  }
  
  .carterdata h4 {
    font-size: 1.5rem;
  }
  
  .carterdata p {
    font-size: 1.2rem;
  }
  