/* General styles */
.career-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  /* Team section */
  .team {
    text-align: center;
    margin: 10rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .team h1 {
    font-size: 3rem;
    font-weight: bold;
    color: #fff;
    margin: 0;
  }
  
  strong {
    color: #15DFED;
  }
  
  .team p {
    color: #fff;
    font-size: 1.2rem;
    margin-top: 1rem;
  }
  
  .team button {
    background-color: black;
    border-color: #fff;
    padding: 10px 40px;
    font-weight: bold;
    margin-top: 2rem;
    font-size: 1rem;
  }
  
  /* Why Us section */
  .whyus {
    text-align: center;
    margin: 5rem 0;
    padding: 20px;
  }
  
  .whyus h1 {
    color: #15DFED;
    font-size: 2rem;
    font-weight: bold;
    margin: 0;
  }
  
  .whyus p {
    width: 80%;
    font-size: 1.2rem;
    margin: 2rem auto;
  }
  
  /* Career points section */
  .whypoint {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
  }
  
  .career {
    text-align: center;
    margin: 3rem;
  }
  
  .career img {
    margin: 1rem;
  }
  
  .career h3 {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
  
  .career p {
    font-size: 1rem;
    text-align: center;
    width: 70%;
    margin: 0 auto;
  }
  
  /* Join Our Team section */
  .join {
    text-align: center;
    margin: 5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .join h1 {
    font-size: 2rem;
    color: #fff;
    font-weight: bold;
    margin: 0;
  }
  
  .join p {
    font-size: 1.2rem;
    color: #fff;
    width: 80%;
    margin: 2rem auto;
  }
  