.rews{
    padding: 100px;
  }
  
  .reviewContainer11{
    flex-direction: row;
    display: flex;
    justify-content: space-evenly;
    /* border:2px solid grey; */
    border: 1px solid rgba(71, 73, 75, 1);
    padding: 10px;
    border-radius: 10px;

  }

  .card22 {
    display: flex;
    flex-direction: column;
    height: 200px;
    background-image: url("../../assets/services/ww.jpeg");
    background-size: cover;
    background-position: center;
    border-radius:10px;
    width: 150px;
    /* margin-top: 10px; */
    /* margin: 20px; */
  
  }
  .card23 {
    display: flex;
    flex-direction: column;
    height: 200px;
    background-image: url("../../assets/ourwork/rrr.png");
    background-size: cover;
    background-position: center;
    border-radius:10px;
    width: 150px;
    margin-top: 10px;

    /* margin: 20px; */
  
  }
  
  .card33 {
    display: flex;
    flex-direction: column;
    height:311px;
    background-image: url("../../assets/services/qq.jpeg");
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    width: 400px;
    margin-bottom: 25px;


}

.card6{
    flex-direction: row;
    display: flex;
}
.cardcol{
    /* style={{display:"flex",flexDirection:"column",justifyContent:"space-evenly"}} */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.card1time{
    text-align: center;
 border: 0.5px solid rgba(71, 73, 75, 1);
    padding: 10px;
    margin: 10px;
    border-radius: 7px;
    width: 170px;
}
.img2{
    flex-direction: row;
    display: flex;
}
.imgcont{
          flex-direction: column;
          display: flex;
          justify-content: space-evenly;
          width: 100%;
          align-items: end;
}

.imgshadowcont{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: 10px;
    box-shadow: 25px -55px 2px rgba(255, 199, 39, 1);
    align-content: center;
    align-self: center;
    margin-top: 20px;
}
@media all and (min-width: 100px) and (max-width: 500px) {
    .rews{
        padding: 10px;
      }

    .reviewContainer11{
        flex-direction: column;
        display: flex;
        justify-content: center;
        border: 1px solid rgba(71, 73, 75, 1);
        padding: 10px;
        border-radius: 10px;
    
        

    
      }
      .card6{
        flex-direction: column;
        display: flex;
        align-self: center;
        align-content: center;
        justify-content: center;
    }
    /* .card22 {
        display: flex;
        flex-direction: column;
        height:790px;
        background-image: url("../../assets/services/ww.jpeg");
        background-size: cover;
        background-position: center;
        border-radius:10px;
        width: 100%;
      }

      .card23 {
        display: none;
        flex-direction: column;
        height: 200px;
        background-image: url("../../assets/ourwork/rrr.png");
        background-size: cover;
        background-position: center;
        border-radius:10px;
        width: 100%;
        margin-top: 10px;
      
      } */

      .img2{
        flex-direction: column;
        display: flex;
    }

    .card1time{
     border: 0.5px solid rgba(71, 73, 75, 1);
   
        border-radius: 7px;
        width: 100%;
        justify-content: center;
    align-content: center;
    display: flex;
    align-self:center;
    align-items: center;
    }

    /* .card33 {
        display: flex;
        flex-direction: column;
        height:100%;
        background-image: url("../../assets/services/qq.jpeg");
        background-size: cover;
        background-position: center;
        border-radius: 10px;
        width: 100%;
        margin-bottom: 25px;
    
    
    } */
    .card22, .card23, .card33 {
      display: none;
  }

    .imgshadowcont{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-left: 10px;
        align-content: center;
        align-self: center;
        margin-top: 20px;
    }
}

@media all and (min-width: 100px) and (max-width: 768px) {
    .rews{
        padding: 10px;
      }

    .reviewContainer11{
        flex-direction: column;
        display: flex;
        justify-content: center;
        border: 1px solid rgba(71, 73, 75, 1);
        padding: 10px;
        border-radius: 10px;
    
        

    
      }
      .card6{
        flex-direction: column;
        display: flex;
        align-self: center;
        align-content: center;
        justify-content: center;
    }
    .card22 {
      display: flex;
      flex-direction: column;
      height: 726px;
      background-image: url("../../assets/services/ww.jpeg");
      background-size: cover;
      background-position: center;
      border-radius:10px;
      width: 100%;
    }

    .card23 {
      display: flex;
      flex-direction: column;
      height: 726px;
      background-image: url("../../assets/ourwork/rrr.png");
      background-size: cover;
      background-position: center;
      border-radius:10px;
      width: 100%;
      margin-top: 10px;
    
    }

      .img2{
        flex-direction: column;
        display: flex;
    }

    .card1time{
     border: 0.5px solid rgba(71, 73, 75, 1);
   
        border-radius: 7px;
        width: 100%;
        justify-content: center;
    align-content: center;
    display: flex;
    align-self:center;
    align-items: center;
    }

    .card33 {
        display: flex;
        flex-direction: column;
        height:100%;
        background-image: url("../../assets/services/qq.jpeg");
        background-size: cover;
        background-position: center;
        border-radius: 10px;
        width: 100%;
        margin-bottom: 25px;
    
    
    }

    .imgshadowcont{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-left: 10px;
        align-content: center;
        align-self: center;
        margin-top: 20px;
    }
}

@media all and (min-width: 100px) and (max-width: 900px) {
    .rews{
        padding: 10px;
      }

    .reviewContainer11{
        flex-direction: column;
        display: flex;
        justify-content: center;
        border: 1px solid rgba(71, 73, 75, 1);
        padding: 10px;
        border-radius: 10px;
    
        

    
      }
      .card6{
        flex-direction: column;
        display: flex;
        align-self: center;
        align-content: center;
        justify-content: center;
    }
    .card22 {
        display: flex;
        flex-direction: column;
        height: 726px;
        background-image: url("../../assets/services/ww.jpeg");
        background-size: cover;
        background-position: center;
        border-radius:10px;
        width: 100%;
      }

      .card23 {
        display: flex;
        flex-direction: column;
        height: 726px;
        background-image: url("../../assets/ourwork/rrr.png");
        background-size: cover;
        background-position: center;
        border-radius:10px;
        width: 100%;
        margin-top: 10px;
      
      }

      .img2{
        flex-direction: column;
        display: flex;
    }

    .card1time{
     border: 0.5px solid rgba(71, 73, 75, 1);
   
        border-radius: 7px;
        width: 100%;
        justify-content: center;
    align-content: center;
    display: flex;
    align-self:center;
    align-items: center;
    }

    .card33 {
        display: none;
        flex-direction: column;
        height:100%;
        background-image: url("../../assets/services/qq.jpeg");
        background-size: cover;
        background-position: center;
        border-radius: 10px;
        width: 100%;
        margin-bottom: 25px;
    
    
    }

    .imgshadowcont{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-left: 10px;
        align-content: center;
        align-self: center;
        margin-top: 20px;
    }
    .card22, .card23, .card33 {
      display: none;
  }
}


@media all and (min-width: 100px) and (max-width: 1200px) {
   
    .card33 {
        display: none;
        flex-direction: column;
        height:100%;
        background-image: url("../../assets/services/qq.jpeg");
        background-size: cover;
        background-position: center;
        border-radius: 10px;
        width: 100%;
        margin-bottom: 25px;
    
    
    }

    .imgshadowcont{
        display: none;
        justify-content: flex-end;
        align-items: flex-end;
        margin-left: 10px;
        align-content: center;
        align-self: center;
        margin-top: 20px;
    }
    
}
  
  
  
