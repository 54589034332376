

#services-cards {
  padding-bottom: calc(var(--numcards) * var(--card-top-offset));
  /* Make place at bottom, as items will slide to that position*/
  margin-bottom: var(
    --card-margin
  ); /* Don't include the --card-margin in padding, as that will affect the scroll-timeline*/
}

#card_1 {
  --index: 1;
}

#card_2 {
  --index: 2;
}

#card_3 {
  --index: 3;
}

#card_4 {
  --index: 4;
}

.services-card {
  position: sticky;
  top: 0;
  padding-top: calc(var(--index) * var(--card-top-offset));

}
.img_1{
  max-width: 100%;
   height: auto
}
@supports (animation-timeline: works) {
  @scroll-timeline services-cards-element-scrolls-in-body {
    source: selector(body);
    scroll-offsets:
    /* Start when the start edge touches the top of the scrollport */ selector(
          #services-cards
        )
        start 1,
      /* End when the start edge touches the start of the scrollport */
        selector(#services-cards) start 0;
    start: selector(#services-cards) start 1; /* Start when the start edge touches the top of the scrollport */
    end: selector(#services-cards) start 0; /* End when the start edge touches the start of the scrollport */
    time-range: 4s;
  }

  .services-card {
    --index0: calc(var(--index) - 1); /* 0-based index */
    --reverse-index: calc(var(--numcards) - var(--index0)); /* reverse index */
    --reverse-index0: calc(
      var(--reverse-index) - 1
    ); /* 0-based reverse index */
  }

  .card__contentwebpro {
    transform-origin: 50% 0%;
    will-change: transform;

    --duration: calc(var(--reverse-index0) * 1s);
    --delay: calc(var(--index0) * 1s);

    animation: var(--duration) linear scale var(--delay) forwards;
    animation-timeline: services-cards-element-scrolls-in-body;
  }

  @keyframes scale {
    to {
      transform: scale(calc(1.1 - calc(0.1 * var(--reverse-index))));
    }
  }
}

/** DEBUG **/

#debug {
  position: fixed;
  top: 1em;
  left: 1em;
}
#debug::after {
  content: " Show Debug";
  margin-left: 1.5em;
  color: white;
  white-space: nowrap;
}

#debug:checked ~ main {
  --outline-width: 1px;
}



header,
main {
  width: 80vw;
  margin: 0 auto;
}

header {
  /* height: 30vh; */
  display: grid;
  place-items: center;
}

#services-cards {
  list-style: none;
  /* 	outline: calc(var(--outline-width) * 10) solid blue;
 */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(var(--numcards), var(--card-height));
  gap: var(--card-margin);
}

/* .card {
outline: var(--outline-width) solid hotpink;
} */

.card__contentwebpro {
  box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.1), 0 1em 2em rgba(0, 0, 0, 0.1);
  background: rgb(255, 255, 255);
  color: rgb(10, 5, 7);
  border-radius: 1em;
  overflow: hidden;
  height: 92vh;
  display: grid;
  grid-template-areas: "text img";
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;

  align-items: stretch;
  /* 	outline: var(--outline-width) solid lime; */
}

.card__contentwebpro > div {
  grid-area: text;
  width: 80%;
  place-self: center;
  text-align: left;

  display: grid;
  gap: 0em;
  place-items: start;
}

.card__contentwebprowebpro > figure {
  grid-area: img;
  overflow: hidden;
}

.card__contentwebpro > figure > img {
  /* width: 100%;
  height: 100%;
  object-fit: cover; */
  width: 95%;
    height: 95%;
    object-fit: contain;
}

h1 {
  font-weight: 300;
  font-size: 3.5em;
}

h2 {
  font-weight: 300;
  font-size: 2.5em;
}

.main_para{
  font-family: sans-serif;
  font-weight: 300;
  line-height: 1.42vw;
 
}

.btn {
  background: rgb(188 87 36);
  color: rgb(255 255 255);
  text-decoration: none;
  display: inline-block;
  padding: 0.5em;
  border-radius: 0.25em;
  margin-bottom: auto;
}

aside {
  width: 70vw;
  margin: 0 auto;
  text-align: left;
}

aside p {
  margin-bottom: 1em;
}
@media (max-width: 800px){

  .card__contentwebpro > figure > img {
  
   display: flex;
   justify-content: center;
   align-items: start;
   flex: 1;
   width: 80vw;
   height: auto;
   margin-top: 0;
}
.card__contentwebpro {
display: flex;
flex-direction: column-reverse;
height: auto;
align-items: start;
}
.main_para{
  font-size: 2vh;
  line-height: 1.2;
  margin-top: 0;
}
.btn--accent{
  margin-top: 50px;
  margin-bottom: 50px;
}
}