.center-cards {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
  }

  
  /* Media query for mobile view */


  .sticky-form-container {
    position: relative;
  }
  
  .sticky-form {
    position: sticky;
    top: 50px; /* Adjust this value based on your layout and how far from the top the form should become fixed */
    z-index: 100; /* Adjust the z-index if necessary to make the form appear above other content */
  }
  .sticky{
    flex-direction: row;
    display: flex;
    justify-content: space-around;
    /* border: 2px solid wheat; */
    /* flex-wrap: wrap; */

  }

  /* @media all and (min-width:100px) and (max-width: 600px) {
    .center-cards {
      justify-content: center;
    }
    .stickys{
      flex-direction: column;
      display: flex;
      justify-content: space-around;
      border: 2px solid wheat;
    }

  } */
  /* Extra small devices (phones, 600px and down) */
/* @media only screen and (max-width: 600px) {...} */

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .center-cards {
    justify-content: center;
  }
  .sticky{
    flex-direction: column !important;
    display: flex;
    justify-content: space-around;
    /* border: 2px solid wheat; */
  }
}



/*  
    .css-vj1n65-MuiGrid-root{
      flex-direction: column;
    } */