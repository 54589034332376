/* Common styles for both mobile and desktop */
.company-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .News h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    text-align: center; /* Center align the text */
    margin: 0;
  }
  
  .news-card {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
  
  .company {
    text-align: center;
    margin: 1rem;
    padding: 10px;
    max-width: 350px;
    text-align: center;
  }
  
  .company img {
    width: 100%;
    max-width: 350px;
    height: auto;
  }
  
  .company p {
    font-size: 0.9rem;
    text-align: center;
    margin: 0.5rem 0;
  }
  
  /* Media query for screens wider than 768px (laptop view) */
  @media (min-width: 769px) {
    .News h1 {
      font-size: 2.5rem;
    }
  
    .company p {
      font-size: 1rem;
    }
  
    .company img {
      max-width: 350px;
    }
  }
  