.animated-text-overlay-container {
  max-width: 100%;
  margin: 10% auto;
  border: 2px solid #d2ff52;
  padding: 20px;
  border-bottom-right-radius: 25px;
  border-start-start-radius: 25px;
  display: flex;
  justify-content: flex-start; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 70%;
}


.animated-text {
  font-size: 16px;
  color: white;
  font-weight: 900;
  text-align: start;
  animation: moveText 10s linear infinite; 
  display: flex;
  align-content: center;
  align-items: center;
  align-self: center;
  justify-content: center;
}

/* Adjust media queries as needed */
@media all and (max-width: 1200px) {
  .animated-text-overlay-container {
    max-width: 100%;
  margin: 10% auto;
  border: 2px solid #FFFFFF;
  padding: 20px;
  border-bottom-right-radius: 25px;
  border-start-start-radius: 25px;
  display: flex;
  justify-content: flex-start; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 80%;
  }

  .animated-text {
    font-size: 13px;
    font-weight: 600;
  }
}

  @media all and (min-width: 100px) and (max-width: 992px) {
    

    .animated-text-overlay-container {
      max-width: 100%;
    margin: 10% auto;
    border: 2px solid #FFFFFF;
    padding: 20px;
    border-bottom-right-radius: 25px;
    border-start-start-radius: 25px;
    display: flex;
    justify-content: flex-start; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 80%;
    }
    .animated-text {
      font-size: 13px;
      color: rgb(255, 255, 255);
      animation: moveText 10s linear infinite;
     font-weight: 600;

    
    

    }
     
  }

  @media all and (min-width: 100px) and (max-width: 768px) {
    
    .animated-text-overlay-container {
      max-width: 100%;
    margin: 10% auto;
    border: 2px solid #FFFFFF;
    padding: 20px;
    border-bottom-right-radius: 25px;
    border-start-start-radius: 25px;
    display: flex;
    justify-content: flex-start; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 90%;
    }
    
    .animated-text {
      font-size: 12px;
      color: rgb(247, 242, 242);
      animation: moveText 10s linear infinite;
     font-weight: 600;

    
    

    }
     
  }


  @media all and (min-width: 100px) and (max-width: 600px) {
    .animated-text-overlay-container {
      max-width: 100%;
    margin: 10% auto;
    border: 2px solid #FFFFFF;
    padding: 20px;
    border-bottom-right-radius: 25px;
    border-start-start-radius: 25px;
    display: flex;
    justify-content: flex-start; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 90%;
    }
    
    .animated-text {
      font-size: 8px;
      color: white;
      animation: moveText 10s linear infinite;
     font-weight: 600;

    
    

    }
     
  }

  @media all and (min-width: 100px) and (max-width: 500px) {
    

    .animated-text-overlay-container {
      max-width: 100%;
    margin: 10% auto;
    border: 2px solid #FFFFFF;
    padding: 20px;
    border-bottom-right-radius: 25px;
    border-start-start-radius: 25px;
    display: flex;
    justify-content: flex-start; /* Center horizontally */
    align-items: center; /* Center vertically */
    width: 90%;
    }
    
    .animated-text {
      font-size: 8px;
      color: white;
      animation: moveText 10s linear infinite;
     font-weight: 600;
     margin-top: 20px;
     color: white;
    }
     
  }


 




  