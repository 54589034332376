/* style={{ color: 'whitesmoke', fontWeight: 'bold', marginBottom: '15px', textAlign: "start", width: "100%" }} */
.h4{
    color: whitesmoke;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: start;
    width: 100%;
    font-size: 30px !important;
    margin-top: 15px !important;

}
.p{
    text-align: start;
    color: whitesmoke;
    font-size: 18px !important;
}
.h3{
    color: #FFC727;
    font-weight: bold;
    margin-bottom: 15px;
    text-align: start;
    width: 100%;
    margin-top: 10px;
    font-size: 50px !important;
}
.folder{
    font-size: 50px !important;
    color: #FFC727 !important;
    margin-left: 18px !important;
    /* background-color: brown; */
    
}
.ourwork{
    padding: 20px;
    background-color: #000000 !important;
    height: 360px;
    margin-left: 20px;
    margin-top: 20px;
    border-radius: 15;
    /* padding: 40px; */
    display: flex;
    flex-direction: column;
    width: 100%;
}
.mainwho {
    padding: 10px;
    background-color: #000000;
    border: 1px solid #47494B;
    margin-bottom: 100px;
    border-radius: 30px;
    flex-direction: row;
    display: flex;
    width: 100%;
    margin-top: 80px;
}

.whoContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}


/* Media Queries */
@media all and (min-width: 100px) and (max-width: 500px) {
    .mainwho {
        padding: 10px;
        background-color: #000000;
        border: 1px solid #47494B;
        margin-bottom: 100px;
        border-radius: 30px;
        flex-direction: column;
        justify-content: space-evenly;
        display: flex;
    }
    .whoContainer {
        flex-direction: row;
        display: flex;
        width: 100%;
    }

    .ourwork{
        padding: 20px;
        background-color: #000000;
        height: 350px;
        margin-left: 20px;
        margin-top: 30px;
        border-radius: 15;
        /* padding: 40px; */
        display: flex;
        flex-direction: column;
        width: 100%;
        /* font-size: small; */
    }
    .h4{
        color: whitesmoke;
        font-weight: bold;
        margin-bottom: 15px;
        text-align: start;
        width: 100%;
        font-size: 23px !important;
        margin-top: 15px !important;

    }
    .p{
        text-align: start;
        color: whitesmoke;
        font-size: 15px !important;
        margin-top: 15px !important;
    }
    .h3{
        color: #FFC727;
        font-weight: bold;
        margin-bottom: 15px;
        text-align: start;
        width: 100%;
        margin-top: 10px;
        font-size: 30px !important;
    }
    .folder{
        font-size: 35px !important;
        color: #FFC727 !important;
        margin-left: 18px !important;
        /* background-color: brown; */
        
    }

}

@media all and (min-width: 501px) and (max-width: 900px) {
    .mainwho {
        padding: 10px;
        background-color: #000000;
        border: 1px solid #47494B;
        margin-bottom: 100px;
        border-radius: 30px;
        flex-direction: column;
        justify-content: space-evenly;
        display: flex;
    }
    .whoContainer {
        flex-direction: row;
        display: flex;
        width: 100%;
    }
    .h4{
        color: whitesmoke;
        font-weight: bold;
        margin-bottom: 15px;
        text-align: start;
        width: 100%;
        font-size: 25px !important;
        margin-top: 15px !important;

    }
    .p{
        text-align: start;
        color: whitesmoke;
        font-size: 18px !important;
        margin-top: 15px !important;

    }
    .h3{
        color: #FFC727;
        font-weight: bold;
        margin-bottom: 15px;
        text-align: start;
        width: 100%;
        margin-top: 10px;
        font-size: 35px !important;
    }
    .folder{
        font-size: 40px !important;
        color: #FFC727 !important;
        margin-left: 18px !important;
        /* background-color: brown; */
        
    }

}

@media all and (min-width: 901px) and (max-width: 1100px) {
    .mainwho {
        padding: 10px;
        background-color: #000000;
        border: 1px solid #47494B;
        margin-bottom: 100px;
        border-radius: 30px;
        flex-direction: column;
        justify-content: space-evenly;
        display: flex;
    }
    .whoContainer {
        flex-direction: row;
        display: flex;
        width: 100%;
    }

    .h4{
        color: whitesmoke;
        font-weight: bold;
        margin-bottom: 15px;
        text-align: start;
        width: 100%;
        font-size: 30px !important;
        margin-top: 15px !important;

    }
    .p{
        text-align: start;
        color: whitesmoke;
        font-size: 22px !important;
        margin-top: 15px !important;

    }
    .h3{
        color: #FFC727;
        font-weight: bold;
        margin-bottom: 15px;
        text-align: start;
        width: 100%;
        margin-top: 10px;
        font-size: 40px !important;
    }
    .folder{
        font-size: 45px !important;
        color: #FFC727 !important;
        margin-left: 18px !important;
        /* background-color: brown; */
        
    }
}
