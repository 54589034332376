/* Common styles for both mobile and desktop */
.research-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin-top: 100px;
  }
  
  .provide h1 {
    font-size: 2rem;
    font-weight: bold;
    color: #fff;
    margin: 0; /* Remove margin to fix spacing */
  }
  
  .provide strong {
    color: #15DFED; /* Highlight strong text with a different color */
  }
  
  .provide p {
    color: #fff;
    font-size: 1rem;
    margin: 0; /* Remove margin to fix spacing */
    text-align: center; /* Center align the text */
  }
  
  /* card */
  .cardcontent4 {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }
  
  .research {
    text-align: center;
    margin: 1rem;
    padding: 10px;
    max-width: 400px;
    text-align: center;
  }
  
  .research img {
    width: 100%;
    max-width: 350px;
    height: auto;
  }
  
  .description1 h5 {
    font-size: 1rem;
    text-align: center;
  }
  
  .description1 p {
    font-size: 0.9rem;
    text-align: center;
    margin: 0.5rem 0;
  }
  
  /* Media query for screens wider than 768px (laptop view) */
  @media (min-width: 769px) {
    .provide h1 {
      font-size: 2.5rem;
    }
  
    .provide p {
      font-size: 1.2rem;
    }
  
    .research img {
      max-width: 350px;
    }
  
    .description1 h5 {
      font-size: 1rem;
    }
  
    .description1 p {
      font-size: 0.9rem;
      text-align: center;
    }
  }
  