.LandingPage-container {
    width: 100%;
    text-align: center;
    
  }
  

  /* .LandingPage-container {
    position: relative;
  } */
  
  /* Other styles */
/*   
  .MuiDrawer-root {
    z-index: -11200; 
  } 
  */
  



  
  .Font {
    position: relative;
    animation-name: example;
    animation-duration: 14s;
    animation-delay: -11s;
  }
  
  @keyframes example {
    0% { left: 0px; top: 0px; }
    25% { left: 200px; top: 0px; }
    50% { left: 200px; top: 200px; }
    75% { left: 0px; top: 110px; }
    100% { left: 0px; top: 0px; }
  }
  
  a {
    text-decoration: none;
  }
  
  .LandingPage-first {
    display: flex;
    margin-top: 25px;
    /* border: 2px solid wheat; */
    justify-content: space-around;
    flex-direction:row ;

  }

.LandingPage-first {
  display: flex;
  flex-direction: column; /* Default layout: column */
  background-color: #091523;
  justify-content: center;
  align-items: center;
}

.LandingPage-2nd {
  display: flex;
  /* border: 2px solid wheat; */
  justify-content: space-around;
  flex-direction:column ;
  align-items: center;
  flex-wrap: wrap;
/* margin-top: 12px; */

}

.LandingPage-2nd {
display: flex;
flex-direction: column; /* Default layout: column */
/* border:2px solid wheat; */
/* margin-top: -360px; */
/* width: 60%; */


}
.content1 {
  flex: 1; /* Take up all available space in the column layout */
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .LandingPage-first {
    flex-direction: row; /* Switch to row layout on screens wider than 768px */
  }

.content1{
  margin-bottom: 100px;
}
    
}

.content {
  flex: 1; /* Take up all available space in the column layout */
  margin-bottom: 20px;
}



.image-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.img1 {
  max-width: 100%; /* Ensure the image doesn't exceed its container width */
  border-radius: 20px;
}



  

/* main */


#hero1{
  background:url(https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/fzk7pcahtdcecscbcnny);
   background-size:cover;
   background-position:center center;
   background-attachment:fixed;
 }
 #hero2{background: url(https://res.cloudinary.com/self-taken/image/upload/f_auto,q_auto/v1/Left%20card/rpjkr6enp232lhv4qbf1);
   background-size:cover;
   background-position:center center;
   background-attachment:fixed;
 }
 .hero, .content{
   text-align:center; 
   position:relative;
   width: 100%;
   /* height: 600px; */
 }
 
 .inner{
   min-height:800px;
   position: relative;
   background-color: #17163E;
 }
 

 
 .copy{
   /* position:absolute; s */
   top:50%; 
   height:10em; 
   margin-top:-5em; 
   width:100%;
 }
 
 .hero h1, .hero p{ 
   color:#fff;
 }
 
 .content h1, .content p{
   color:#333;
 }
 
 h1{
   /* margin:0px; */
   font-family: 'Roboto Slab', serif;
   font-weight:400;
   font-size:32px;
   padding:0px 20px;
   color: #fff;
 }
 
 p{
   font-family: 'Noto Sans', sans-serif; 
   font-size:14px;
   padding:0px 20px;
 }
 
 

