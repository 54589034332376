/* .container123 {
  width: 250px;
  height: 30px;
  background: red;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(45deg, #ff0000, #ff9900, #eeff00, #00ff2a, #1100ff, #d700f3, #00f320, #b40c00, #ffd901, #f3003d);
  background-size: 400%;
  animation: animate 5s linear infinite;
}

.inners {
  width: 240px;
  height: 25px;
  background: #d2ff52;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inners .parass {
  margin: 0;
  color: white;
  font-size: 7px;
}

.mobile {
  font-weight: bold;
  color: black;
}

@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}

.flag-button {
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.flag-button::after {
  display: none !important;
}

.parasss {
  cursor: pointer;
  color: rgb(244, 11, 11);
  font-size: 14px;
  font-weight: 700;
}

.flags {
  overflow: hidden;
  white-space: nowrap;
  width: 200px;
  height: 55px;
}

@media all and (max-width: 500px) {
  .container123 {
    width: 100px;
    height: 30px;
    background: red;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(45deg, #ff0000, #ff9900, #eeff00, #00ff2a, #1100ff, #d700f3, #00f320, #b40c00, #ffd901, #f3003d);
    background-size: 400%;
    animation: animate 5s linear infinite;
  }
  
  .parasss {
    cursor: pointer;
    color: black;
    font-size: 6px;
    font-weight: 700;
  }

  .flags {
    overflow: hidden;
    white-space: nowrap;
    width: 50px;
    height: 55px;
  }

  .inners {
    width: 95px;
    height: 27px;
    background: #d2ff52;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobile {
    font-weight: bold;
    color: black;
    font-size: 5px;
  }
} */
/*  HERE STARTS THE CODE FOR CIRCLE GRADIENT  */
.container123 {
  width: 250px;
  height: 30px;
  background: red;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(45deg, 
  #ff0000, 
  #ff9900, 
  #eeff00, 
  #00ff2a, 
  #1100ff, 
  #d700f3,
  #00f320,
  #b40c00,
  #ffd901, 
  #f3003d);
  background-size: 400%;
  animation: animate 5s linear infinite;
}

.inners {
  width: 240px;
  height: 25px;
  background: #D2FF52;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inners .parass {
  margin: 0;
  color: white;
  font-size: 7px;
}
.mobile{
  font-weight: bold;
  color: black;
}



/* Keyframes doing the animation */
/*  If you want to make it animate faster, make the "5s" less -(seconds)  */
@keyframes animate {
0% {
  background-position: 0%;
}
100% {
  background-position: 400%;
}
}




.flag-button {
border: none !important;
background: transparent !important;
box-shadow: none !important;
padding: 0 !important;
}

/* Hide the arrow */
.flag-button::after {
display: none !important;
}


.parasss{
cursor: pointer;
color: rgb(244, 11, 11);
font-size: 14px;
font-weight: 700;
}


.flags{
overflow: hidden;
white-space: nowrap;
width: 200px;
height: 35px;
}
@media all and (min-width: 100px) and (max-width: 500px) {
.container123 {
  width: 100px;
  height: 30px;
  background: red;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(45deg, 
  #ff0000, 
  #ff9900, 
  #eeff00, 
  #00ff2a, 
  #1100ff, 
  #d700f3,
  #00f320,
  #b40c00,
  #ffd901, 
  #f3003d);
  background-size: 400%;
  animation: animate 5s linear infinite;
}
.parasss{
  cursor: pointer;
  color: black;
  font-size: 6px;
  font-weight: 700;
}

.flags{
  overflow: hidden;
  white-space: nowrap;
  width: 50px;
  height: 35px;
}

.inners {
  width: 95px;
  height: 27px;
  background: #D2FF52;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile{
  font-weight: bold;
  color: black;
  font-size: 5px;
}



}