/* Base styles for the container and text */
.weofferTitle {
  font-weight: 600;
  text-align: center;
  color: #333;
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;
  width: 100%;
  box-sizing: border-box;
}

.weofferSubTitle {
  font-weight: 300;
  color: #666;
  margin: 20px 0;
  overflow-wrap: break-word;
  width: 100%;
  box-sizing: border-box;
}

/* General container adjustments */
.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #d2ff52;
  width: 90%;
  max-width: 1000px;
  box-sizing: border-box;
  overflow: hidden;
  padding: 10px; /* General padding */
}

/* Responsive adjustments for very small devices (under 380px) */
@media (max-width: 380px) {
  .weofferTitle {
      font-size: 3.5vw; /* Adjust font size based on viewport width */
  }

  .weofferSubTitle {
      font-size: 2vw; /* Adjust font size based on viewport width */
  }

  .overlay {
      padding: 5px; /* Reduce padding */
  }
}

/* Responsive adjustments for small devices (380px to 480px) */
@media (min-width: 380px) and (max-width: 480px) {
  .weofferTitle {
      font-size: 4vw; /* Adjust font size based on viewport width */
  }

  .weofferSubTitle {
      font-size: 2.5vw; /* Adjust font size based on viewport width */
  }

  .overlay {
      padding: 8px; /* Adjust padding */
  }
}

/* Responsive adjustments for medium devices (tablets) */
@media (min-width: 481px) and (max-width: 800px) {
  .weofferTitle {
      font-size: 5vw; /* Adjust font size based on viewport width */
  }

  .weofferSubTitle {
      font-size: 3vw; /* Adjust font size based on viewport width */
  }

  .overlay {
      padding: 15px; /* Adjust padding */
  }
}

/* Responsive adjustments for large devices (desktops) */
@media (min-width: 801px) {
  .weofferTitle {
      font-size: 60px; /* Standard font size */
  }

  .weofferSubTitle {
      font-size: 20px; /* Standard font size */
  }

  .overlay {
      padding: 20px; /* Standard padding */
  }
}

/* General image adjustments */
img {
  max-width: 100%; /* Ensure image does not overflow container */
  height: auto; /* Maintain aspect ratio */
}
